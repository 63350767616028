
import { bus, i18n } from '@/main';
import FInput from '@/components/Form/FInput.vue'
import FSelect from '@/components/Form/FSelect.vue'
import FForm from '@/components/Form/FForm.vue'
import FButton from '@/components/Button/FButton.vue'
import Table from '@/components/Table/Table.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';
import SimplePagination from '@/components/Pagination/SimplePagination.vue';
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SearchCatalogService } from '@/services/SearchCatalogService';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';

@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.viewCatalogsTitle
  }), 
  components:{ 
    FInput,
    FButton,
    FSelect,
    FForm,
    Table,
    SvgIcon, 
    SimplePagination
  }
})
export default class CatalogSearch extends Vue {
  store = SearchCatalogService;
  
  get columns(){
    return  [
      { key:'title', label: i18n.t('R.LIT_CatalogName').toString()},
      { key:'songCount', label: i18n.t('R.LIT_NumberOfSongs').toString()},
      { key:'id', label: i18n.t('R.LIT_CatalogID').toString()},
    ]
  }

  get catalogs(){ 
    return SearchCatalogService.searchResults;
  }

  get searched() { 
    return !!SearchCatalogService.searchResults;
  }

  async mounted() { 
    SearchCatalogService.getQueryParams();
    await SearchCatalogService.performSearch();
    bus.$on('territoryUpdated', SearchCatalogService.onSetTerritory);
  }

  beforeDestroy() {
    bus.$off('territoryUpdated', SearchCatalogService.onSetTerritory);
    SearchCatalogService.searchResults = [];
  }

  @Watch('$route')
  async performSearch() {
    SearchCatalogService.getQueryParams();
    SearchCatalogService.performSearch();
  }

  resetSearch(){ 
    SearchCatalogService.updateSearchText('');
  }
}
